import React, {useState, useEffect} from 'react';
import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import withBadge from 'hocs/withBadge';
import UserPlaceholder from 'assets/img/user-placeholder.png';
import { useHistory, useLocation } from 'react-router-dom';
import { useCookies } from 'react-cookie';

import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

function Header() {
  const id = "1";
  let history = useHistory();
  const [user, setUser] = useState([]);
  const [isOpenNotificationPopover, setIsOpenNotificationPopover] = useState(false);
  const [isNotificationConfirmed, setIsNotificationConfirmed] = useState(false);
  const [isOpenUserCardPopover, setIsOpenUserCardPopover] = useState(false);
  const [cookies, setCookies, removeCookies] = useCookies(['currentUser','isLoggedIn']);

  const location = useLocation()
  var currentPathName = location.pathname


  const toggleNotificationPopover = () => {
    setIsOpenNotificationPopover(!isOpenNotificationPopover);

    if (!isNotificationConfirmed) {
      setIsNotificationConfirmed(true);
    }
  }

  const toggleUserCardPopover = () => {
    setIsOpenUserCardPopover(!isOpenUserCardPopover);
  }

  const handleSidebarControlButton = (event) => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  //const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
  // const isLoggedIn = sessionStorage.getItem("IsLoggedIn");
  
  const currentUser = cookies.currentUser;
  const isLoggedIn = cookies.isLoggedIn;
  // const currentUser = sessionStorage.getItem('currentUser');

  // if (!isLoggedIn) {
  //   history.push("/login");
  // } else {
  //   // if (currentUser) {
  //   //   const retrieveUser = async () => {
  //   //     const response = fetch('https://businessriver.tv/api/user/'+currentUser.id, {
  //   //     method: 'GET', // *GET, POST, PUT, DELETE, etc.
  //   //     mode: 'cors', // no-cors, cors, *same-origin
  //   //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
  //   //     credentials: 'omit', // include, *same-origin, omit
  //   //     headers: {
  //   //         //'Access-Control-Allow-Origin': "*",
  //   //         "Access-Control-Allow-Origin" : "*",
  //   //         "Access-Control-Allow-Credentials" : true ,
  //   //         'Content-Type': 'application/json',
  //   //         "Access-Control-Allow-Methods": "GET,HEAD,OPTIONS,POST,PUT",
  //   //         "Access-Control-Allow-Headers": "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers"
  //   //     },
  //   //     redirect: 'follow', // manual, *follow, error
  //   //     referrer: 'client', // no-referrer, *client
  //   //     })
  //   //     .then((response) => {
  //   //       return response.json();
  //   //     })
  //   //     .then((events) => {
  //   //         return events.data;
  //   //     });
  //   //     return response;
  //   //   };
    
  //   //   useEffect(() => {
  //   //     const getUser = async () => {
  //   //       const selectedUser = await retrieveUser();
  //   //       //console.log(selectedUser);
  //   //       setUser(selectedUser);
  //   //     };
  //   //     getUser();
  //   //   }, []);
  //   // } else {
  //   //   history.push("/login");
  //   // }
  // }

  useEffect(() => {
    const currentUser = cookies.currentUser;
    
    if (!currentUser) {
 
      history.push("/login");
       
    } 
},[]);

  const logOut = (e) => {
    // sessionStorage.clear();
    setCookies('currentUser', "", {path:'/'});
    setCookies('isLoggedIn', false, {path:'/'});
    history.push("/login");
  }

  /*const profilePage = (e) => {
    history.push("/profile");
  }*/

  return (
    <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>
      <Nav navbar>
        {/*<SearchInput />*/}
      </Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
          {/*<NavLink id="Popover1" className="position-relative">
            {isNotificationConfirmed ? (
              <MdNotificationsNone
                size={25}
                className="text-secondary can-click"
                onClick={toggleNotificationPopover}
              />
            ) : (
              <MdNotificationsActiveWithBadge
                size={25}
                className="text-secondary can-click animated swing infinite"
                onClick={toggleNotificationPopover}
              />
            )}
          </NavLink>
          <Popover
            placement="bottom"
            isOpen={isOpenNotificationPopover}
            toggle={toggleNotificationPopover}
            target="Popover1"
          >
            <PopoverBody>
              <Notifications notificationsData={notificationsData} />
            </PopoverBody>
            </Popover>*/}
        </NavItem>

        <NavItem>
          <NavLink id="Popover2">
            <Avatar
              onClick={toggleUserCardPopover}
              className="can-click"
              src={UserPlaceholder}
              //src={user.avatar}
            />
          </NavLink>
          <Popover
            placement="bottom-end"
            isOpen={isOpenUserCardPopover}
            toggle={toggleUserCardPopover}
            target="Popover2"
            className="p-0 border-0"
            style={{ minWidth: 250 }}
          >
            <PopoverBody className="p-0 border-light">
              <UserCard
                //title={user.first_name +" "+ user.last_name}
                title="Administrator"
                subtitle={currentUser}
                //subtitle={user.email}
                //text="Last updated 3 mins ago"
                className="border-light"
                avatar={UserPlaceholder}
                //avatar={user.avatar}
              >
                <ListGroup flush>
                  {/*<ListGroupItem tag="button" action className="border-light">
                    <MdPersonPin /> Profile
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light">
                    <MdInsertChart /> Stats
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light">
                    <MdMessage /> Messages
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light">
                    <MdSettingsApplications /> Settings
                  </ListGroupItem>
                  <ListGroupItem tag="button" action className="border-light">
                    <MdHelp /> Help
                  </ListGroupItem>*/}
                  <ListGroupItem tag="button" onClick={logOut} action className="border-light">
                    <MdExitToApp /> Signout
                  </ListGroupItem>
                </ListGroup>
              </UserCard>
            </PopoverBody>
          </Popover>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

export default Header;
