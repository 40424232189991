import React, { useState } from 'react';
import VEP from 'assets/img/logo/logo2.png';
import PropTypes from 'prop-types';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';
import { useHistory } from "react-router-dom";
import { Card, Col, Row } from 'reactstrap';

const initialFormDataRegister = Object.freeze({
  email: '',
  password: '',
  password_confirmation: '',
})

const initialFormDataLogin = Object.freeze({
  email: '',
  password: '',
})

//class AuthForm extends React.Component {
function BisAuthForm(props) {
  let history = useHistory();
  const [formDataRegister, setFormDataRegister] = useState(initialFormDataRegister)
  const [formDataLogin, setFormDataLogin] = useState(initialFormDataLogin)
  const [formStatus, setFormStatus] = useState("standby")

  const {
    showLogo,
    usernameLabel,
    usernameInputProps,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children,
    onLogoClick,
  } = props;


  const [isLogin, setIsLogin] = useState(props.authState === STATE_BIS_LOGIN)

  const [isSignup, setIsSignup] = useState(props.authState === STATE_BIS_SIGNUP)

  const changeAuthState = authState => event => {
    event.preventDefault();
    props.onChangeAuthState(authState);
  };

  /*handleSubmit = event => {
    event.preventDefault();
  };*/

  const renderButtonText = (e) => {
    const { buttonText } = props;
    if (!buttonText && isLogin) {
      return 'Login';
    }

    if (!buttonText && isSignup) {
      return 'Register';
    }
    return buttonText;
  }

  const handleChange = (e) => {
    if (isLogin === true) {
      setFormDataLogin({
        ...formDataLogin,
        [e.target.name]: e.target.value,
      })
    } else {
      setFormDataRegister({
        ...formDataRegister,
        [e.target.name]: e.target.value,
      })
    }
    //console.log(formDataLogin);
  }

  async function loginSubmit(e) {
    setFormStatus("loading")
    const response = await fetch("https://businessriver.tv/api/login/admin", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "client",
      body: JSON.stringify(formDataLogin),
    });

    const json = await response.json();
    if (json.status == "Success") {
      //console.log(json);
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      sessionStorage.setItem("isLoggedIn", true);
      setFormStatus("standby");
      history.push("/");
    } else {
      //console.log(json);
      if (json.errors.email) {
        alert(json.errors.email[0]);
      } if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus("standby");
    }
  }

  async function signupSubmit(e) {
    e.preventDefault();
    setFormStatus("loading");
    const response = await fetch("https://businessriver.tv/api/register", {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "omit",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrer: "client",
      body: JSON.stringify(formDataRegister),
    });

    const json = await response.json();
    console.log(json);
    if (json.status == "Success") {
      history.push("/");
      sessionStorage.setItem('currentUser', JSON.stringify(json.data));
      setFormStatus("standby");
    } else {
      if (json.errors.email) {
        alert(json.errors.email[0]);
      } if (json.errors.password) {
        alert(json.errors.password[0]);
      } else {
        alert(json.errors[0]);
      }
      setFormStatus("standby");
    }
  }
  return (
    <div id="bis-form">
      {isLogin && (
        <Form onSubmit={signupSubmit} className="bis_login_form">
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={VEP}
                className="rounded"
                style={{ height: 60 }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <p className="text-center form_login">MEMBER LOGIN</p>
          <FormGroup>
            {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
            <Input {...usernameInputProps} name="email_address_login" placeholder="Email Address" className="mb-3" required onChange={handleChange} />
          </FormGroup>
          <FormGroup>
            {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
            <Input {...passwordInputProps} name="password_login" placeholder="Password" className="mb-3" required onChange={handleChange} />
          </FormGroup>

          {isLogin && (
            <a href="#" className="forget_password">Forget Password</a>
          )}
          <Button
            size="lg"
            type="submit"
            className="border-0 mt-4"
            block
            onClick={isLogin ? loginSubmit : signupSubmit}>
            {renderButtonText()}
          </Button>
          <div className="text-center">
            <a href="#bis-signup" className="bis-link" onClick={changeAuthState(STATE_BIS_SIGNUP)}>
              Register
            </a>
          </div>
        </Form>
      )}

      {isSignup && (
        <Form onSubmit={signupSubmit} className="bis_signup_form">
          {showLogo && (
            <div className="text-center pb-4">
              <img
                src={VEP}
                className="rounded"
                style={{ height: 60 }}
                alt="logo"
                onClick={onLogoClick}
              />
            </div>
          )}
          <Row className="card_bg_white">
            <Col lg={12}>
              <FormGroup>
                {/* <Label for={usernameLabel}>{usernameLabel}</Label> */}
                <Input {...usernameInputProps} name="email_address_signup" placeholder="Email Address" className="mb-3" required onChange={handleChange} />
              </FormGroup>
              <Row>
                <Col lg={6}>
                  <FormGroup>
                    {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
                    <Input {...passwordInputProps} name="password_signup" placeholder="Password" required onChange={handleChange} />
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup>
                    {/* <Label for={passwordLabel}>{passwordLabel}</Label> */}
                    <Input name="confirm_password" placeholder="Confirm Password" required onChange={handleChange} />
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <h3>COMPANY DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <Input type="text" name="company_name" placeholder="Company Name" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="text" name="company_age" placeholder="Company Age" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="select" name="company_structure" className="mb-3" required  onChange={handleChange}>
                <option>Company Structure</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col lg={6}>
              <Input type="select" name="company_size" className="mb-3" required onChange={handleChange}>
                <option>Company Size</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col lg={6}>
              <Input type="select" name="industry" className="mb-3" required onChange={handleChange}>
                <option>Industry</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col lg={6}>
              <Input type="select" name="company_turnover" className="mb-3" required onChange={handleChange}>
                <option>Company Turnover</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col lg={6}>
              <Input type="text" name="company_email_address" placeholder="Company Email Address" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="tel" name="company_phone_number" placeholder="Company Phone Number" required onChange={handleChange} />
            </Col>
          </Row>
          <h3>PERSONAL INFORMATION</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <Input type="text" name="first_name" placeholder="First Name" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="text" name="last_name" placeholder="Last Name" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="email" name="email_2" placeholder="Email Address" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="tel" name="phone_number" placeholder="Phone Number" required onChange={handleChange} />
            </Col>
          </Row>
          <h3>ADDRESS DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={12}>
              <Input type="text" name="address_line_1" placeholder="Address Line 1" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={12}>
              <Input type="text" name="address_line_2" placeholder="Address Line 2" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="text" name="city" placeholder="City" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="text" name="province" placeholder="Province/State" className="mb-3" required onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="select" name="residential" className="mb-3" required onChange={handleChange}>
                <option>Residential</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </Input>
            </Col>
            <Col lg={6}>
              <Input type="text" name="eir_code" placeholder="EIR Code" required onChange={handleChange} />
              
            </Col>
            <Col lg={6}>
              <Input type="text" name="country" placeholder="Country" required onChange={handleChange} />
            </Col>
          </Row>
          {/* <h3>CONTACT DETAILS</h3>
          <Row className="card_bg_white">
            <Col lg={6}>
              <Input type="tel" name="phone_number" placeholder="Phone Number" onChange={handleChange} />
            </Col>
            <Col lg={6}>
              <Input type="email" name="email_2" placeholder="Email Address" onChange={handleChange} />
            </Col>
          </Row> */}
          <Button
            size="lg"
            type="submit"
            className="border-0 mt-4"
            block
            onClick={isLogin ? loginSubmit : signupSubmit}>
            {renderButtonText()}
          </Button>
          <div className="text-center">
            <span className="mr-2">Already have an account?</span> 
            <a href="#bis-login" className="bis-link" onClick={changeAuthState(STATE_BIS_LOGIN)}>
              Login
            </a>
          </div>
        </Form>
      )}

      {formStatus != "standby" ?
        <>
          {isLogin ?
            <Button
              size="lg"
              className="border-0 mt-4"
              block
              type="submit"
              onClick={isLogin ? loginSubmit : signupSubmit}>
              Logging in...
            </Button>
            :
            <Button
              size="lg"
              className="border-0 mt-4"
              block
              type="submit"
              onClick={isLogin ? loginSubmit : signupSubmit}>
              Signing up...
            </Button>
          }
        </>
        :
        null
        // <Button
        //   size="lg"
        //   type="submit"
        //   className="border-0 mt-4"
        //   block
        //   onClick={isLogin ? loginSubmit : signupSubmit}>
        //   {renderButtonText()}
        // </Button>
      }

      {/* <div className="text-center pt-1">
        <h6>
          {isSignup ? (
            <a href="#bis-login" className="bis-link" onClick={changeAuthState(STATE_BIS_LOGIN)}>
              Login
            </a>
          ) : (
            <a href="#bis-signup" className="bis-link" onClick={changeAuthState(STATE_BIS_SIGNUP)}>
              Register
            </a>
          )}
        </h6>
      </div> */}
      {children}

    </div>
  );
}


export const STATE_BIS_LOGIN = 'LOGIN';
export const STATE_BIS_SIGNUP = 'SIGNUP';

BisAuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_BIS_LOGIN, STATE_BIS_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

BisAuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  usernameInputProps: {
    type: 'email',
    placeholder: 'your@email.com',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password',
  },
  onLogoClick: () => { },
};

export default BisAuthForm;
