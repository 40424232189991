import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { STATE_BIS_LOGIN, STATE_BIS_SIGNUP } from 'components/BisAuthForm';
import GAListener from 'components/GAListener';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import BisAuthPage from 'pages/BisAuthPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

const AlertPage = React.lazy(() => import('pages/AlertPage'));
const AuthModalPage = React.lazy(() => import('pages/AuthModalPage'));
const BadgePage = React.lazy(() => import('pages/BadgePage'));
const ButtonGroupPage = React.lazy(() => import('pages/ButtonGroupPage'));
const ButtonPage = React.lazy(() => import('pages/ButtonPage'));
const CardPage = React.lazy(() => import('pages/CardPage'));
const ChartPage = React.lazy(() => import('pages/ChartPage'));
const DashboardPage = React.lazy(() => import('pages/DashboardPage'));
const DropdownPage = React.lazy(() => import('pages/DropdownPage'));
const FormPage = React.lazy(() => import('pages/FormPage'));
const InputGroupPage = React.lazy(() => import('pages/InputGroupPage'));
const ModalPage = React.lazy(() => import('pages/ModalPage'));
const ProgressPage = React.lazy(() => import('pages/ProgressPage'));
const TablePage = React.lazy(() => import('pages/TablePage'));
const TypographyPage = React.lazy(() => import('pages/TypographyPage'));
const WidgetPage = React.lazy(() => import('pages/WidgetPage'));
const AwardPage = React.lazy(() => import('pages/AwardPage'));
const CreateAwardPage = React.lazy(() => import('pages/CreateAwardPage'));
const SetupAwardPage = React.lazy(() => import('pages/SetupAwardPage'));
const EntryPage = React.lazy(() => import('pages/EntryPage'));
const ProfileViewPage = React.lazy(() => import('pages/ProfileViewPage'));
const BisAwardsPage = React.lazy(() => import('pages/BisAwardsPage'));
const BisSetupAwardPage = React.lazy(() => import('pages/BisSetupAwardPage'));
const BisSingleAwardPage = React.lazy(() => import('pages/BisSingleAwardPage'));
const BisPaymentForm = React.lazy(() => import('pages/BisPaymentForm'));
const BisReceipt = React.lazy(() => import('pages/BisReceipt'));


const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`;
};

function AppMain(props) {
  const breakpoint = props.breakpoint;
  // const loggedIn = useState(sessionStorage.getItem("loggedIn"));
  //const loggedIn = "true";
  return (
    <BrowserRouter basename={getBasename()}>
      <GAListener>
        <Switch>
          <LayoutRoute
            exact
            path="/login"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/bis-login"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_LOGIN} />
            )}
          />
          <LayoutRoute
            exact
            path="/bis-signup"
            layout={EmptyLayout}
            component={props => (
              <BisAuthPage {...props} authState={STATE_BIS_SIGNUP} />
            )}
          />
          <MainLayout breakpoint={breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Route exact path="/" component={DashboardPage} />
              <Route exact path="/login-modal" component={AuthModalPage} />
              <Route exact path="/buttons" component={ButtonPage} />
              <Route exact path="/cards" component={CardPage} />
              <Route exact path="/widgets" component={WidgetPage} />
              <Route exact path="/typography" component={TypographyPage} />
              <Route exact path="/alerts" component={AlertPage} />
              <Route exact path="/tables" component={TablePage} />
              <Route exact path="/badges" component={BadgePage} />
              <Route
                exact
                path="/button-groups"
                component={ButtonGroupPage}
              />
              <Route exact path="/dropdowns" component={DropdownPage} />
              <Route exact path="/progress" component={ProgressPage} />
              <Route exact path="/modals" component={ModalPage} />
              <Route exact path="/forms" component={FormPage} />
              <Route exact path="/input-groups" component={InputGroupPage} />
              <Route exact path="/charts" component={ChartPage} />
              <Route exact path="/awards" component={AwardPage} />
              <Route exact path="/awards/setup" component={SetupAwardPage} />
              <Route exact path="/awards/create" component={CreateAwardPage} />
              <Route exact path="/entry" component={EntryPage} />
              <Route exact path="/profile" component={ProfileViewPage} />
              <Route exact path="/bis-awards" component={BisAwardsPage} />
              <Route exact path="/awards/bis-setup" component={BisSetupAwardPage} />
              <Route exact path="/awards/bis-single-award" component={BisSingleAwardPage} />
              <Route exact path="/bis-payment" component={BisPaymentForm} />
              <Route exact path="/bis-receipt" component={BisReceipt} />
            </React.Suspense>
          </MainLayout>
          <Redirect to="/" />
        </Switch>
      </GAListener>
    </BrowserRouter>
  );
}


const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(AppMain);
